import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, VerticalGridLines} from 'react-vis';
import {TextField, Typography} from "@material-ui/core";

type Task = {
    id: string,
    min: number,
    expected: number,
    max: number,
    prerequisites: number[]
};

function stub_getCumulativeDistributionFunctionForTasks(tasks: Array<Task>): Array<{ x: number, y: number }> {
    return [
        {x: 1, y: 0.0},
        {x: 2, y: 0.1},
    ]
}

const stubRequest = {
    simulation_count: 10,
    scaling_factor: 1,
    start: "1",
    end: "16",
    tasks: [
        {id: "1", min: 1, expected: 3, max: 6, dependencies: []},
        {id: "2", min: 2, expected: 5, max: 10, dependencies: ["1"]},
        {id: "3", min: 3, expected: 7, max: 14, dependencies: ["2"]},
        {id: "4", min: 1, expected: 2, max: 4, dependencies: ["3"]},
        {id: "5", min: 1, expected: 2, max: 5, dependencies: ["4"]},
        {id: "6", min: 1, expected: 2, max: 5, dependencies: ["5"]},
        {id: "7", min: 1, expected: 2, max: 5, dependencies: ["6"]},
        {id: "8", min: 1, expected: 2, max: 5, dependencies: ["7"]},
        {id: "9", min: 1, expected: 2, max: 5, dependencies: ["8"]},
        {id: "10", min: 1, expected: 2, max: 5, dependencies: ["9"]},
        {id: "11", min: 1, expected: 2, max: 5, dependencies: ["10"]},
        {id: "12", min: 1, expected: 2, max: 5, dependencies: ["11"]},
        {id: "13", min: 1, expected: 2, max: 5, dependencies: ["12"]},
        {id: "14", min: 1, expected: 2, max: 5, dependencies: ["13"]},
        {id: "15", min: 1, expected: 2, max: 5, dependencies: ["14"]},
        {id: "16", min: 1, expected: 2, max: 5, dependencies: ["15"]},
    ]
}

const defaultJsonValue = JSON.stringify(stubRequest);

function getExpectedTotal(tasks: Array<{ min: number, expected: number, max: number }>): number {
    let sum = 0;
    for (var task of tasks) {
        sum += task.expected;
    }
    return sum;
}

function App() {
    let [cdf, setCdf] = useState();
    const [value, setValue] = React.useState('');
    const [expectedValue, setExpectedValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        setExpectedValue(getExpectedTotal(JSON.parse(event.target.value).tasks));
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
            body: value
        };
        fetch('https://api.point5.io/simulate', requestOptions)
            .then(response => response.json())
            .then(data => setCdf(data.cdf))
            .catch(error => setCdf(stub_getCumulativeDistributionFunctionForTasks([])));
    }, [value]);
    

    return (
        <>

            <div className="App">
                <XYPlot
                    width={640}
                    height={480}>
                    <HorizontalGridLines/>
                    <VerticalGridLines/>
                    <LineSeries
                        data={cdf}/>
                    <XAxis/>
                    <YAxis/>
                </XYPlot>
            </div>
            <div>
                <Typography variant="caption">Expected Total : {expectedValue}</Typography>
            </div>
            <div>&nbsp;</div>
            <div>
                <TextField
                    id="outlined-multiline-static"
                    label="JSON Project Definition Input"
                    multiline
                    rows={20}
                    defaultValue={defaultJsonValue}
                    variant="outlined"
                    fullWidth={true}
                    onChange={handleChange}
                />

            </div>
        </>
    );
}

export default App;
